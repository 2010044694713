import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App';
import './index.css';
// import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { SessionProvider } from './components/context/SessionContext';

import { Toaster } from 'react-hot-toast';

import { createRouter } from '@tanstack/react-router';
import { DebugProvider, useDebug } from './components/context/DebugContext';
import SipSyncRouter from './router';
import { routeTree } from './routeTree.gen';

Sentry.init({
  dsn: 'https://62511c7ac27550eab578091171ecc3ca@o251649.ingest.us.sentry.io/4507430461112320',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.NODE_ENV == 'development' ? 'development' : 'production'
});

console.info('Starting app');
console.info('GIT_TYPE: ', import.meta.env.VITE_GIT_REF_TYPE);
console.info('GIT_REF_NAME: ', import.meta.env.VITE_GIT_REF_NAME);
console.info('GIT_SHA: ', import.meta.env.VITE_GIT_SHA);

// Create a client
const queryClient = new QueryClient();

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPendingComponent: LoadingComponent,
  defaultNotFoundComponent: NotFoundComponent,
  defaultErrorComponent: ErrorComponent,
  context: { user: null, session: null, logout: async () => undefined, queryClient }
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const theme = localStorage.getItem('theme') == 'light' ? 'light' : 'dark';
document.getElementsByTagName('html')[0]?.setAttribute('data-theme', theme);

// eslint-disable-next-line no-undef
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        const err = error as Error;
        console.debug(error);
        if (err.message.includes('Server version mismatch')) {
          setTimeout(() => {
            window.location.reload;
          }, 5 * 1000);
          return (
            <>
              Server version mismatch. App is likely undergoing maintenance, please try again later.
              <br />
              This page will try to reload in 60 seconds.
            </>
          );
        }
        return (
          <>
            App is likely in maintenance mode, please try again later... <br />
            {err.message}
          </>
        );
      }}
    >

      <DebugProvider>
        <Suspense fallback={<LoadingComponent />}>
          <QueryClientProvider client={queryClient}>
            <SessionProvider>
              <Toaster containerClassName={'z-50'} />
              <SipSyncRouter router={router} />
            </SessionProvider>
          </QueryClientProvider>
        </Suspense>
      </DebugProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

function ErrorComponent({ error, reset }: { error; reset }) {
  return (
    <div className='absolute/ inset-0 flex h-full w-full grow flex-col items-center justify-center bg-inherit text-xl'>
      Uh oh, something went wrong{' '}
      <button className='btn btn-warning' onClick={reset}>
        Ignore
      </button>
      Try reloading the page
      <div tabIndex={0} className='collapse collapse-plus mt-8 w-fit border border-error '>
        <div className='collapse-title text-xl font-medium'>Click to see more details</div>
        <div className='collapse-content'>
          <p>{error.message}</p>
          <br></br>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
}

function NotFoundComponent() {
  return (
    <div className='inset-0 flex h-full w-full flex-col items-center justify-center bg-inherit text-xl'>
      <span className='collapse'>Not found</span>
    </div>
  );
}

function LoadingComponent(props) {
  const { debug } = useDebug();
  return (
    <div className='absolute/ inset-0 my-auto flex h-full w-full flex-col items-center justify-center bg-inherit text-xl'>
      <span>Loading</span>
      <span className='loading loading-spinner text-secondary'></span>
      {debug && 'props\n' + JSON.stringify(props)}
    </div>
  );
}
