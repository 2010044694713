import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_app/recipes/_list/explore')({
  component: () => (
    <div className='flex flex-col'>
      Hello /_app/recipes/_list/explore!
      <div>Shared</div>
    </div>
  )
});
