import AccountCircle from '@mui/icons-material/AccountCircle';

import { useSession } from '@/components/context/SessionContext';
import { Link } from '@tanstack/react-router';
import { useContext } from 'react';
import { ThemeSwitcher } from './ThemeSwitcher';
import { DebugContext } from './context/DebugContext';

export default function NavBar() {
  const { session, user, logout } = useSession();
  const isLoggedIn = !!user; //!!session;

  const { debug } = useContext(DebugContext);
  if (debug) {
    console.debug('dev mode');
  }
  // const { session, user } = useContext(SessionContext);

  const NavButton = ({ href, children, className }: { href?: string; children; className? }) => {
    return (
      <>
        <Link className={`btn btn-ghost mx-2 dark:text-white ${className}`} to={href || ''}>
          {children}
        </Link>
      </>
    );
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout();
    window.location.href = '/login';
    // await navigate({ to: '/login' });
  };

  return (
    // <LoggedInContext.Consumer>
    // {({ isLoggedIn }) => {
    // console.log('navbar logged in: ', isLoggedIn);
    // return (
    <nav key={isLoggedIn ? 1 : 0} className='navbar box-border w-full bg-primary'>
      <div className='navbar-start'>
        <ThemeSwitcher />
      </div>
      {debug && (
        <div className='navbar-center w-96 dark:text-white'>
          session: {JSON.stringify(session)} <br /> user:{JSON.stringify(user)}
        </div>
      )}
      <div className='navbar-end float-right flex  flex-row'>
        {debug && (
          <button
            onClick={() => {
              throw Error();
            }}
          >
            Test
          </button>
        )}
        {!isLoggedIn && (
          <NavButton className='' href='/signup'>
            Signup
          </NavButton>
        )}

        {!isLoggedIn && (
          <NavButton className='' href='/login'>
            Login
          </NavButton>
        )}

        {isLoggedIn && (
          <div className='dropdown dropdown-end dropdown-hover'>
            {/* // onClick={handleMenu}
            className='p-2'

            // component={RouterLink}
            // href='/auth/profile'
            // variant='contained'
          > */}
            <button
              tabIndex={0}
              className='btn btn-circle btn-ghost rounded-full text-white hover:text-accent'
            >
              <AccountCircle />
            </button>
            <ul
              tabIndex={0}
              className='menu dropdown-content z-[100] rounded-box bg-base-200 p-2 shadow'
            >
              <li>
                <Link className='' to='/my-bar'>
                  My Bar
                </Link>
              </li>
              <li>
                <Link activeOptions={{ exact: true }} to='/account'>
                  Account
                </Link>
              </li>
              <li>
                <Link to='/account/settings'>Settings</Link>
              </li>

              {/* <li>
                <Link to='/account/settings'>Settings</Link>
              </li> */}

              <li>
                {/* <form action={logout}> */}
                <button onClick={handleLogout}>Logout</button>
                {/* </form> */}
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
    //     );
    //   }}
    // </LoggedInContext.Consumer>
  );
}
