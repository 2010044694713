// import Link from 'next/link';

import { createFileRoute, Link } from '@tanstack/react-router';
import { useSession } from '@/components/context/SessionContext';

export const Route = createFileRoute('/_app/account/')({ component: AccountPage });
export default function AccountPage() {
  // return {
  //   redirect: {
  //     destination: '/auth/login',
  //     permanent: false,
  //   },
  // };
  const { user } = useSession();
  return (
    <div className={'flex flex-col items-center'}>
      <h1>Account</h1>
      <Link href='/account/recipes'>Recipes</Link>
      <div className={'card bg-base-200 shadow-lg'}>
        <div className={'card-body flex flex-col items-center'}>
          <div className={'mask mask-circle h-20 w-20'}>
            <div className={'h-full w-full bg-gray-400'}></div>
          </div>
          <div className={'w-full'}>
            <span>Name: </span> {user?.username}
          </div>
        </div>
      </div>
    </div>
  );
}
