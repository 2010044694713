import { createFileRoute } from '@tanstack/react-router';
import api from '@/lib/api';

type RecipeSearch = {
  page?: number;
  // filter: string
  limit?: number;
  // sort: ProductSearchSortOptions
};

export const Route = createFileRoute('/_app/recipes/_list/')({
  validateSearch: (search: Record<string, unknown>): RecipeSearch => {
    // validate and parse the search params into a typed state
    return {
      page: Number(search?.page ?? 1),
      limit: Number(search?.limit ?? 8)
    };
  },

  beforeLoad: async ({ search, context }) => {
    const { page, limit } = search;
    const queryClient = context.queryClient;

    queryClient.prefetchQuery({
      queryKey: ['recipes', 'all', { page, limit, orderBy: 'recent ' }],
      queryFn: async () => {
        return api
          .get('recipes/all', {
            searchParams: { page: page!, limit: limit!, search: '', orderBy: 'recent' }
          })
          .json();
      },
      staleTime: 1000 * 60 * 5
      // retry: 1
    });
  }
});
