import { createFileRoute, Link, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_app/recipes/_list')({
  component: () => (
    <div className='flex w-full flex-col items-center'>
      <div role='tablist' className='tabs-boxed tabs'>
        <Link
          to='/recipes'
          role='tab'
          className={`tab`}
          activeOptions={{ exact: true, includeSearch: false }}
          activeProps={{ className: 'tab-active' }}
        >
          My Recipes
        </Link>
        <Link
          to='/recipes/explore'
          role='tab'
          activeProps={{ className: 'tab-active' }}
          className={`tab`}
        >
          Explore
        </Link>
        {/* todo recently explored? shared with me? */}
      </div>
      Hello /_app/recipes/_list!
      <Outlet />
    </div>
  )
});
