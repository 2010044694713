import { SessionContextType } from '@/components/context/SessionContext';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import React, { Suspense } from 'react';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
// import { TanStackRouterDevtools } from '@tanstack/router-devtools';
const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      );

export type TRouterContext = SessionContextType & { queryClient };

export const Route = createRootRouteWithContext<TRouterContext>()({
  component: () => (
    <>
      <NavBar />
      <main className='min-h-full/ relative flex w-full grow flex-col'>
        <Suspense fallback={<div>Loading...</div>}>
          <Outlet />
        </Suspense>
      </main>
      <Footer />
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  )
});
